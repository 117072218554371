@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes colorChange {
    0% { color: #0095FF; }
    25% { color: #2ECC71; }
    50% { color: #E74C3C; }
    75% { color: #F39C12; }
    100% { color: #0095FF; }
  }
  
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
  
  .title-animation {
    animation: fadeInDown 0.8s ease-out, colorChange 10s infinite;
  }
  
  .button-container {
    position: relative;
    /* overflow: hidden; */
  }
  
  .button-animation {
    animation: pulse 2s infinite;
  }
  
  .button-animation:hover {
    animation: none;
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  .ripple {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    animation: ripple 1s linear;
  }
  
  .tag-animation {
    opacity: 0;
    animation: fadeInUp 0.5s ease-out forwards;
  }
  
  .robot-icon {
    animation: rotate 5s linear infinite;
  }
  
  .container {
    position: relative;
    overflow: hidden;
  }
  
  .background-animation {
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200%;
    z-index: -1;
    animation: rotate 20s linear infinite;
  }
  
  .floating {
    animation: floating 3s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% { transform: translate(0, 0px); }
    50% { transform: translate(0, 15px); }
    100% { transform: translate(0, -0px); }
  }

  .main-cn {
    position: relative; /* Ensure the container is positioned */
    overflow: hidden; /* Prevent overflow of pseudo-element */
  }
  
  .main-cn::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute; /* Position it absolutely */
    top: 0; /* Cover the entire area */
    left: 0;
    right: 0;
    bottom: 0;
    background: url(/public/img/others/canvas.jpg); /* Background image */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the image */
    opacity: 0.1; /* Set desired opacity (0 to 1) */

  }
  